<template>
  <div
    id="system-pie-charts"
    :style="{width: '100%', height: '540px',margin:'auto'}"
  />
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, watchEffect } from 'vue'

interface IEquipSystem {
  name: string,
  value: number,
  icon: string,
  color: string,
  percentage?: number
}

export default defineComponent({
  name: 'SystemPieCharts',
  props: ['data'],
  setup (props) {
    const { proxy }: any = getCurrentInstance()
    const store = useStore()
    const responsively = computed<boolean>(() => store.getters.GET_RESPONSIVELY_MODE)
    const chartsData = computed<IEquipSystem[]>(() => {
      const arr = reactive<IEquipSystem[]>([])
      props.data.forEach((item: any) => {
        if (item.value !== 0) {
          arr.push(item)
        }
      })
      return arr
    })
    onMounted(() => {
      const systemPieCharts = proxy.$echarts.init(document.getElementById('system-pie-charts'))
      const render = () => {
        systemPieCharts.setOption({
          tooltip: {
            trigger: 'item'
          },
          legend: {
            bottom: '0%',
            left: 'center'
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            feature: {
              dataView: { readOnly: false },
              saveAsImage: {}
            }
          },
          xAxis: {
            show: false
          },
          yAxis: {
            show: false
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: responsively.value ? ['30%', '50%'] : ['50%', '80%'],
              emphasis: {
                label: {
                  show: true
                }
              },
              data: chartsData.value
            }
          ]
        })
      }
      watchEffect(() => {
        render()
      })
      setTimeout(() => {
        systemPieCharts.resize()
        window.addEventListener('resize', () => systemPieCharts.resize())
        // window.onresize = () => {
        //   hourBarCharts.resize()
        // }
      }, 200)
    })
    return {
      chartsData
    }
  }
})
</script>
